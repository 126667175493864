import React, { type PropsWithChildren } from 'react';

import styles from './skeleton-renderer.scss';

type SkeletonRendererProps = PropsWithChildren<{
  isLoaded: boolean;
  width?: number;
}>;

const SkeletonRenderer: React.FC<SkeletonRendererProps> = ({ isLoaded, children, width }) => {
  /** Makes sure skeleton loader bounding box is the same size as the text it's going to display */
  const BLANK_TEXT_FOR_BETTER_LCP = <>&nbsp;</>;

  return isLoaded ? (
    <>{children}</>
  ) : (
    <div className={styles.root} style={{ '--width': width ? `${width}px` : '300px' }}>
      {BLANK_TEXT_FOR_BETTER_LCP}
    </div>
  );
};

export default SkeletonRenderer;
