import React, { useState } from 'react';
import classNames from 'classnames';

import { LikeIcon } from '../icons/like-icon';
import styles from './like-button.scss';

type LikeButtonProps = {
  isLiked: boolean;
  isDisabled: boolean;
};

const LikeButton: React.FC<LikeButtonProps> = ({ isLiked, isDisabled }) => {
  const [isAnimationEnabled, setIsAnimationEnabled] = useState(false);

  const handleAnimation = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (isDisabled) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
    if (!isLiked) {
      setIsAnimationEnabled(true);
    }
  };

  return (
    <span
      onClick={handleAnimation}
      className={classNames(
        'like-button',
        styles.heart,
        { [styles.active]: isLiked },
        { [styles.isAnimationEnabled]: isAnimationEnabled },
      )}
      data-hook="like-button"
    >
      {[1, 2, 3, 4].map((pos) => (
        <span key={pos} aria-hidden className={styles[`pos${pos}`]}>
          <span className={styles.heartSparkle} />
        </span>
      ))}
      <span aria-hidden className={styles.heartRing} />
      <LikeIcon className={styles.heartBody} />
    </span>
  );
};

export default LikeButton;
